<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title color="white">Dettaglio programma</ion-title>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <div class="appuntamento_container">
                    <div class="appuntamento_title">
                        {{ appuntamento.projects_name }}
                    </div>
                    <div class="field">
                        <div class="title">Cliente</div>
                        <div class="value">
                            {{ riferimentoCliente(appuntamento) }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Indirizzo</div>
                        <div class="value">
                            <a
                                :href="googleMapsLink"
                                target="_blank"
                                class="mobile_link"
                            >
                                <span>{{ appuntamento.projects_address }}</span>
                                <span>{{ appuntamento.projects_city }}</span>
                            </a>
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Data</div>
                        <div class="value">
                            {{ dateFormat(appuntamento.appuntamenti_giorno) }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Orari</div>
                        <div class="value">
                            {{ `${appuntamento.appuntamenti_ora_inizio} - ${appuntamento.appuntamenti_ora_fine}` }}
                        </div>
                    </div>
                    <div class="field field_list">
                        <div class="title">Persone</div>
                        <div class="persone" v-for="(persona, index) in appuntamento.appuntamenti_persone" :key="index">
                            <div>{{ persona }}</div>
                        </div>
                    </div>
                    <div v-if="appuntamento.appuntamenti_automezzi" class="field field_list">
                        <div class="title">Automezzi</div>
                        <div class="automezzi" v-for="(automezzo, index) in appuntamento.appuntamenti_automezzi" :key="index">
                            <div>{{ automezzo }}</div>
                        </div>
                    </div>
                    <div class="field field_description">
                        <div class="title">Note</div>
                        <div class="value">
                            {{ appuntamento.appuntamenti_note ? `${appuntamento.appuntamenti_note}` : "-" }}
                        </div>
                    </div>

                    <div v-if="!rapportinoCreato" class="container_button">
                        <div class="btn_crea_rapportino" @click="newRapportino()">Crea rapportino</div>
                    </div>
                    <div v-else class="existing_rapportino">Rapportino creato per questo appuntamento</div>
                </div>

                <!-- <div class="container_button">
                    <div class="btn_crea_rapportino" @click="newRapportino()">Crea rapportino</div>
                </div> -->
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    //IonBackButton,
    IonTitle,
    IonContent,
    IonIcon,
    IonButton,
    modalController,
} from "@ionic/vue";
import { arrowBackOutline, arrowForwardCircle } from "ionicons/icons";

import { defineComponent, ref, computed, onMounted } from "vue";

import moment from "moment";

import { dateFormat } from "@/services/utils";
import { openToast } from "@/services/toast";

import NuovoRapportinoAppuntamento from "@/components/rapportini/NuovoRapportinoAppuntamento.vue";
import DettaglioRapportino from "@/components/rapportini/DettaglioRapportino.vue";
//import InterventoProgramma from "@/components/interventi/InterventoProgramma.vue";
//import DettaglioRapportino from "@/components/rapportini/DettaglioRapportino.vue";

export default defineComponent({
    name: "ProgrammaLavoroDetail",
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    components: {
        IonPage,
        IonHeader,
        IonToolbar,
        IonButtons,
        //IonBackButton,
        IonTitle,
        IonContent,
        IonIcon,
        IonButton,
    },
    setup(props, context) {
        //console.log(props.data);
        const loading = ref(false);
        const appuntamento = ref([]);
        appuntamento.value = { ...props.data };
        console.log(appuntamento.value);
        const rapportinoCreato = ref(appuntamento.value.rapportino_creato);


        const googleMapsLink = computed(() => {            
            const query = [];

            if (appuntamento.value.projects_city) {
                query.push(appuntamento.value.projects_city);
            }
            if (appuntamento.value.projects_address) {
                query.push(appuntamento.value.projects_address);
            }
            if (appuntamento.value.projects_province) {
                query.push(appuntamento.value.projects_province);
            }

            return query.length 
                ? `https://maps.google.com/?q=${query.join(' ')}`
                : '#';  // Link vuoto se mancano i dati
        });

        /**
         * ! Handle close modal by clicking on Chiudi button
         */
         const closeModal = () => {
            modalController.dismiss();
        };
        /**
         * ! Close modal after form data submit
         */
        async function closeModalOnSubmit(flag, res) {
            if (flag) {
                await modalController.dismiss(res);
            } else {
                await modalController.dismiss();
            }
        }


        /**
         * 
         * ! Open rapportino detail
         * 
         */
         async function openRapportinoDetail(rapportino) {
            const modal = await modalController.create({
                component: DettaglioRapportino,
                componentProps: {
                    data: rapportino,
                },
            });
            modal.onDidDismiss().then((detail) => {
                //Posso aver aperto questa pagina solo dopo aver creato un rapportino
                //quindi marco nuovamente il rapporitno come creato
                rapportinoCreato.value = true;
            });
            return modal.present();
        }

        /**
         * ! Open modal to create new rapportino
         */
        async function newRapportino() {
            const modal = await modalController.create({
                component: NuovoRapportinoAppuntamento,
                componentProps: {
                    data: appuntamento.value,
                },
            });
            modal.onDidDismiss().then((detail) => {
                if (detail.data != undefined) {
                    //openDetail(detail.data);
                    rapportinoCreato.value = true;
                    //closeModalOnSubmit(true, detail.data);
                    openRapportinoDetail(detail.data);
                    openToast("Rapportino salvato con successo", "toast_success");
                }
            });
            return modal.present();
        }


        /**
         * ! Print correct customer info
         */
        const riferimentoCliente = (cliente) => {
            if (cliente.customers_company) {
                return cliente.customers_company;
            } else if (cliente.customers_name && !cliente.customers_last_name) {
                return `${cliente.customers_name}`;
            }
            return `${cliente.customers_name} ${cliente.customers_last_name}`;
        };

        /**
         * Format datetime to DD/MM/YY HH:mm
         */
        function dateTimeFormat(dateTime) {
            if (dateTime && moment.isDate(new Date(dateTime))) {
                return moment(dateTime).format("DD/MM/YYYY HH:mm");
            } else {
                return "-";
            }
        }

        onMounted(() => {
            //checkPartecipazione();
        });

        return {
            loading,
            dateFormat,
            arrowBackOutline,
            closeModal,
            arrowForwardCircle,
            appuntamento,
            dateTimeFormat,
            newRapportino,
            riferimentoCliente,
            rapportinoCreato,
            googleMapsLink
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
ion-content {
    --background: #f2f2f2;
}
ion-button {
    --color: #ffffff;
}
.loading_spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bg-primary {
    background: var(--ion-color-primary, #3880ff) !important;
    padding: 8px;
    color: #ffffff;
}

ion-item {
    font-size: 14px;
}
ion-label {
    margin: 0;
}

/* New rules */
.page_content {
    width: 100%;
    position: relative; /* to be used for new richiesta btn */
    padding: 16px;
    min-height: 100%;
    background: #f2f2f2;
}

/* lead v2*/
.appuntamento_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 16px;
    border-radius: 8px;
    /*box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);*/
    background: #ffffff;
    box-shadow: 0px 2px 5px 0px #00000040;
}
.appuntamento_container .appuntamento_title {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    color: #1d1d1b;
    margin-bottom: 16px;
}
.appuntamento_container .city {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
    margin-bottom: 8px;
}
.appuntamento_container .field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0px;
    border-bottom: 0.5px solid #7676803d;
}
.appuntamento_container .field_list {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 16px 0px;
    border-bottom: 0.5px solid #7676803d;
}
.appuntamento_container .field_list .title {
    margin-bottom: 4px;
}
.appuntamento_container .field_list .persone,
.appuntamento_container .field_list .automezzi {
    font-size: 14px;
}
.appuntamento_container .field_description {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    border-bottom: none;
}
.appuntamento_container .field_description .title {
    width: 100%;
    margin-bottom: 4px;
}
.appuntamento_container .field:last-of-type {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 16px 0px;
    border-bottom: none;
}
.appuntamento_container .field_note {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 12px;
    border-bottom: none;
}

.field .title,
.field_note .title {
    font-size: 14px;
    font-weight: 500;
    color: #1d1d1b;
}
.field_note .title {
    margin-bottom: 8px;
}
.field .value,
.field_note .value {
    font-size: 14px;
}
.value .badge {
    font-size: 10px;
    font-weight: 600;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 4px;
}

.container_button {
    width: 100%;
    margin-top: 16px;
}
.btn_crea_rapportino {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 12px;
    margin-bottom: 8px;
    /* color: #ffffff;
    background-color: #086fa3; */
    background-color: rgb(219 234 254);
    color: rgb(37 99 235);
}

.partecipazione {
    margin-top: 32px;
    font-size: 16px;
    font-weight: 500;
    color: #475569;
    text-align: center;
}

.existing_rapportino {
    width: 100%;
    padding: 8px 12px;
    font-size: 14px;
    margin-top: 12px;
    font-weight: 600;
    text-align: center;
    border-radius: 4px;
    background-color: rgb(219 234 254);
    color: rgb(37 99 235);
}
.mobile_link {
    font-weight: 500;
    text-decoration: none;
    color: #086fa3;
    text-align: right;
    display: flex;
    flex-direction: column;
}
</style>
